<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >

    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <div class="font-small-2">
          {{ subtitle }}
        </div>
        <h5 class="mb-1">
          <b-badge variant="primary">
            {{ data.sales }} {{ data.currency_name }}
          </b-badge>
          /
          <b-badge variant="primary">
            {{ data.target }} {{ data.currency_name }}
          </b-badge>

        </h5>
        <b-card-text
          v-if="remainingSales>0"
          class="text-muted font-small-2"
        >
          <span>Hedef tamamlanmasına kalan satış: </span> <span class="font-weight-bolder">{{ remainingSales }} {{ data.currency_name }}</span>
        </b-card-text>
        <b-card-text
          v-if="remainingSales<=0"
          class="text-muted font-small-2"
        >
          <span>Hedef Tamamlandı</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="170"
          :options="goalOverviewRadialBar"
          :series="[data.target_complete_rate]"
        />
      </b-col>
    </b-row>
    <!-- apex chart -->

  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol, BCardText, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BBadge,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          type: 'radialBar',
          toolbar: {
            show: false,
          },
        },
        legend: { show: false },
        dataLabels: {
          enabled: false,

        },
        labels: ['Tamamlanan'],
        colors: [$goalStrokeColor2],
        stroke: {
          width: 0,
        },

        grid: {
          padding: {
            right: -50,
            bottom: -25,
            top: -25,
            left: -20,
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],

      },
    }
  },
  computed: {
    remainingSales() {
      const remaining = this.data.target - this.data.sales
      return remaining
    },
  },
  created() { },
}
</script>
